import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { config } from '../../config';

import './index.scss'; // Make sure to create and import the corresponding CSS file
import { fetchUsers } from '../../redux/Slices/thunks';

const Signup = () => {

  const [signUpData, setSignUpData] = useState({
    name: '',
    email: '',
    password: ''
  });

  const [message, setMessage] = useState(null); // State to hold response message
  const [error, setError] = useState(null); // State to hold error messages
  const dispatch = useDispatch();

  const handleChange = e => {
    setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
  };

  const handleSignup = async e => {
    e.preventDefault();
    try {
      const response = await fetch(config.API_URI + '/api/users/register', { // Use the full URL if necessary
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(signUpData)
      });

      console.log(response);

      if (!response.ok) {
        // Handle non-200 responses
        const errorData = await response.json(); // Read the response as JSON
        setError(errorData.message); // Set error message
        setMessage(null); // Clear success message
      } else {
        const data = await response.json(); // Read the response as JSON
        setMessage(data.message); // Set success message
        setError(null); // Clear error message
        dispatch(fetchUsers())


      }
    } catch (error) {
      console.error('Error:', error.message);
      setError('An error occurred while signing up'); // Set generic error message
      setMessage(null); // Clear success message
    }
  };

  return (
    <>
      <Helmet>
        <title>Signup - Zoobbe</title>
      </Helmet>

      <div className="login-container">
        <div className="login-box">
          <h1 className="login-logo">Zoobbe</h1>
          <h2 className="login-heading">Sign up to continue</h2>
          <form className="signup-form" onSubmit={handleSignup}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={signUpData.name}
              onChange={handleChange}
              className="login-input"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={signUpData.email}
              onChange={handleChange}
              className="login-input"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={signUpData.password}
              onChange={handleChange}
              className="login-input"
              required
            />
            {message && <p className="success-message">{message}</p>} {/* Display success message */}
            {error && <p className="error-message">{error}</p>} {/* Display error message */}

            <p className="legal-message">
              By signing up, I accept the Zoobbe, Inc.{' '}
              <Link to="https://www.atlassian.com/legal/cloud-terms-of-service" target="_blank" rel="noreferrer noopener" className="css-1y8hiba">
                Cloud Terms of Service
              </Link>{' '}
              and acknowledge the{' '}
              <Link to="https://www.atlassian.com/legal/privacy-policy" target="_blank" rel="noreferrer noopener" className="css-1y8hiba">
                Privacy Policy
              </Link>.
            </p>
            <button type="submit" className="login-button">Continue</button>
          </form>
          <p className="login-or">Or continue with:</p>
          <div className="login-social-buttons">
            <button className="social-button google">Google</button>
            <button className="social-button microsoft">Microsoft</button>
            <button className="social-button apple">Apple</button>
            <button className="social-button slack">Slack</button>
          </div>
          <div className="login-links">
            <Link to="/login" className="login-link">Already have an Zoobbe account? Log in</Link>
          </div>
          <footer className="login-footer">
            <p className="login-atlassian">Zoobbe, Inc.</p>
            <p className="login-footer-text">One account for Zoobbe, Jira, Confluence and more.</p>
            <div className="login-privacy">
              <Link to="#" className="login-privacy-link">Privacy Policy</Link>
              <span> · </span>
              <Link to="#" className="login-privacy-link">User Notice</Link>
            </div>
            <p className="login-footer-note">
              This site is protected by reCAPTCHA and the Google
              <Link to="#" className="login-privacy-link">Privacy Policy</Link> and
              <Link to="#" className="login-privacy-link">Terms of Service</Link> apply.
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Signup;
