// components/DynamicPopover.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { fetchMembers, memberInfo } from '../../redux/Slices/memberSlice';
import ImagePlaceholder from './ImagePlaceholder';

const MemberImage = ({ cardId, members, size }) => {
    const dispatch = useDispatch();

    const handleMemberInfo = (e, contentId, member) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };
        dispatch(togglePopover({ contentId, position, targetId: target.id }));
        dispatch(memberInfo({ member }));
    };

    // const members = useSelector(state => state.member.cardMembers);


    return (
        <div className='members-info'>
            {
                members?.map((member, index) => {

                    return  (
                        member.profilePicture ? (
                            <span
                                className='avatar'
                                key={index}
                                onClick={(e) => handleMemberInfo(e, 'memberInfo', member)}
                            >
                                <img style={{ width: size, height: size }} src={member.profilePicture} alt={member.name} />
                            </span>
                        ) : (
                            <span
                                className='avatar'
                                key={index}
                                onClick={(e) => handleMemberInfo(e, 'memberInfo', member)}
                            >
                                <ImagePlaceholder size={size} text={member.username} />
                            </span>
                        )
                    )
                })
            }
        </div>
    );
};

export default MemberImage;
