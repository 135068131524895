import React, { useEffect } from 'react';

const Spinner = ({ size = 30, color = '#3498db', speed = 1.5 }) => {

  
  // CSS styles for the spinner
  const spinnerStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  const svgStyle = {
    animation: `rotate ${speed}s linear infinite`,
  };

  const circleStyle = {
    fill: 'none',
    strokeWidth: size / 7, // Dynamic stroke width based on size
    strokeLinecap: 'round',
    animation: `animate-stroke ${speed * 0.75}s ease-in-out infinite`,
  };

  // Inject CSS keyframes dynamically
  useEffect(() => {
    const styles = `
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes animate-stroke {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
          stroke: ${color}; /* Primary color */
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35;
          stroke: rgba(52, 152, 219, 0.6); /* Lighter shade */
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124;
          stroke: rgba(52, 152, 219, 0.3); /* Darker shade */
        }
      }
    `;

    const styleSheet = document.createElement('style');
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    // Cleanup to remove the style element when component unmounts
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [color, speed]);

  return (
    <div style={spinnerStyle}>
      <svg className="circular-loader" viewBox="25 25 50 50" style={svgStyle}>
        <circle className="loader-path" cx="50" cy="50" r="20" style={circleStyle}></circle>
      </svg>
    </div>
  );
};

export default Spinner;
