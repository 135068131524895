import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { config } from '../../config';

// Async action to fetch activities
export const fetchActivities = createAsyncThunk(
    'activities/fetchActivities',
    async ({ cardId, isActivityDetails }, { rejectWithValue }) => {
        const token = localStorage.getItem('accessToken');
        let activityQuery = '';

        if (isActivityDetails !== undefined) {
            activityQuery = `?isActivityDetails=${isActivityDetails}`;
        }

        try {
            const response = await fetch(
                `${config.API_URI}/api/cards/${cardId}/activities${activityQuery}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );


            if (!response.ok) {
                throw new Error('Failed to fetch activities');
            }

            const data = await response.json();

            console.log({ data });

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Activities slice
const activitiesSlice = createSlice({
    name: 'activities',
    initialState: {
        activities: [],
        loading: false,
        error: null,
    },
    reducers: {
        addCommentOptimistically: (state, action) => {
            state.activities.unshift(action.payload); // Add the new comment at the start of the activities array
        },
        updateCommentOptimistically: (state, action) => {
            const { id, updatedDetails } = action.payload;
            const existingActivity = state.activities.find(activity => activity._id === id);
            if (existingActivity) {
                existingActivity.details = updatedDetails; // Update the comment's details
            }
        },
        deleteCommentOptimistically: (state, action) => {
            const id = action.payload;
            state.activities = state.activities.filter(activity => activity._id !== id); // Remove the comment from the array
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchActivities.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchActivities.fulfilled, (state, action) => {
                state.loading = false;
                state.activities = action.payload;
            })
            .addCase(fetchActivities.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

// Export actions for optimistic updates
export const {
    addCommentOptimistically,
    updateCommentOptimistically,
    deleteCommentOptimistically
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
