import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Provider } from 'react-redux';

import store from './redux/store';
import { getUser, refreshAccessToken } from './utils/helpers';

import Header from './components/Header';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import Workspaces from './components/Workspaces';
import UserProfile from './components/Profile/UserProfile';
import Workspace from './components/Profile/Workspace';
import Boards from './components/Profile/Boards';
import Cards from './components/Profile/CardTable';
import Collaborators from './components/Profile/Collaborators';
import Settings from './components/Profile/Settings';
import Notifications from './components/Workspaces/Notifications';

import Modal from './components/Global/ModalManager';
import Toast from './components/Global/Toast';
import PopOver from './components/Global/PopOver';

import { BoardProvider } from './context/BoardContext';

import './index.css';
import ExportImport from './components/Profile/ExportImport';
import BoardWrapper from './components/Workspaces/BoardWrapper';
import Activity from './components/Profile/Activity';

import './App.scss'

// Component for protected routes
const ProtectedRoute = ({ element: Component, loggedIn, redirectTo = "/login", ...rest }) => (
  loggedIn ? <Component {...rest} /> : <Navigate to={redirectTo} />
);

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userProfileBoardsLink, setUserProfileBoardsLink] = useState('');
  const isCheckingLoginStatus = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const checkLoginStatus = async () => {
      if (isCheckingLoginStatus.current) return;

      isCheckingLoginStatus.current = true;
      const data = await getUser();

      if (!data?.user?._id) {
        const refreshed = await refreshAccessToken();
        setLoggedIn(refreshed);
      } else {
        setLoggedIn(data.user._id);
        setUserProfileBoardsLink(`/u/${data.user.username}/boards`);
      }

      setLoading(false);
    };

    // dispatch(fetchWorkspaces());
    checkLoginStatus();
  }, [dispatch]);

  if (loading) {
    return null;
  }

  return (
    <Provider store={store}>
      <Router>
        {loggedIn && <Header />}
        <BoardProvider>
          <Routes>
            <Route path="/workspace" element={<ProtectedRoute element={Workspace} loggedIn={loggedIn} />} />
            <Route path="/" element={<ProtectedRoute element={() => <Navigate to={userProfileBoardsLink} />} loggedIn={loggedIn} />} />
            <Route path="/u/:userName/profile" element={<ProtectedRoute element={UserProfile} loggedIn={loggedIn} />} />
            <Route path="/u/:userName/boards" element={<ProtectedRoute element={Workspace} loggedIn={loggedIn} />} />
            <Route path="/u/:userName/cards" element={<ProtectedRoute element={Cards} loggedIn={loggedIn} />} />
            <Route path="/u/:userName/activity" element={<ProtectedRoute element={Activity} loggedIn={loggedIn} />} />
            <Route path="/u/:userName/settings" element={<ProtectedRoute element={Settings} loggedIn={loggedIn} />} />
            <Route path="/w/:workspaceSlug" element={<ProtectedRoute element={Workspaces} loggedIn={loggedIn} />} />
            <Route path="/b/:boardId/:boardSlug" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} />} />
            <Route path="/b/:boardId/" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} />} />
            <Route path="/c/:cardId/:cardSlug" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} />} />
            <Route path="/c/:cardId/" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} />} />

            <Route path="/w/:shortId/boards" element={<ProtectedRoute element={Boards} loggedIn={loggedIn} />} />
            <Route path="/w/:shortId/members" element={<ProtectedRoute element={Collaborators} loggedIn={loggedIn} />} />
            <Route path="/w/:shortId/settings" element={<ProtectedRoute element={Settings} loggedIn={loggedIn} />} />
            <Route path="/notifications" element={<ProtectedRoute element={Notifications} loggedIn={loggedIn} />} />

            <Route path="/login" element={!loggedIn ? <Login /> : <Navigate to={`/u/${userProfileBoardsLink}`} />} />
            <Route path="/signup" element={!loggedIn ? <Signup /> : <Navigate to={`/u/${userProfileBoardsLink}`} />} />

            <Route path="/import-export" element={!loggedIn ? <Login /> : <ExportImport />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </BoardProvider>

        <Modal />
        <Toast />
        <PopOver />
      </Router>
    </Provider>
  );
}

export default App;
