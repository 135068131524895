import React, { useState, useRef, useEffect, act } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './scss/Comments.scss';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import { sanitizeHtml, timeAgo } from '../../utils/helpers';
import { config } from '../../config';
import Editor from '../Editor/Editor';
import { resetMentionedIds } from "../../redux/Slices/mentionedSlice";
import { deleteCommentOptimistically, fetchActivities, updateCommentOptimistically } from '../../redux/Slices/activitiesSlice';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';

const Comment = ({ activity, cardId }) => {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const mentionedIds = useSelector((state) => state.mentioned.mentionedMemberIds);

    const [comment, setComment] = useState(activity.details); // Initialize with activity details
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editingComment, setEditingComment] = useState('');
    const quillRef = useRef(null);

    const { handlePopoverClick } = useHandlePopoverClick();


    // Ensure comment is updated if it's a new comment
    useEffect(() => {
        if (activity.actionType === 'ADDED_COMMENT') {
            setComment(activity.details);
        }
    }, [activity]);

    // Focus and set cursor at the end of the text when editing
    useEffect(() => {
        if (editingCommentId === activity._id && quillRef.current) {
            const quill = quillRef.current.getEditor();
            const length = quill.getLength(); // Get the length of the content
            quill.setSelection(length - 1, 0); // Set the cursor at the end
            quill.focus(); // Focus the editor
        }
    }, [editingCommentId]);

    // Handle comment update
    const handleUpdateComment = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('accessToken');

        // Dispatch optimistic update
        dispatch(updateCommentOptimistically({
            id: activity._id,
            updatedDetails: editingComment,
        }));

        setEditingCommentId(null);
        setEditingComment('');


        try {
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/comments/${activity.actionTypeId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ comment: editingComment })
            });

            if (!response.ok) throw new Error('Failed to update comment');

            const updatedComment = await response.json();
            // You can update the state with the actual response data if needed
            setEditingCommentId(null);
            setEditingComment('');
        } catch (error) {
            console.error('Error updating comment:', error);
            // Optionally handle the error and revert optimistic update if needed
        }
    };


    // Handle comment deletion
    const handleDeleteComment = (e, activityId) => {
        const apiUrl = `${config.API_URI}/api/cards/${cardId}/comments/${activity.actionTypeId}`;
        handlePopoverClick(e, 'deleteAction', { type: 'COMMENT', id: activityId, url: apiUrl });
    };


    return (
        <div className="comment-section" id={`comment-section-${activity._id}`}>
            <div className="comment">
                {activity.initiator?.profilePicture ? (
                    <img
                        src={activity.initiator.profilePicture}
                        alt={activity.initiator.name}
                        className="comment__profile-img"
                    />
                ) : (
                    <ImagePlaceholder size={35} text={activity.initiator.name} />
                )}

                <div className="comment__content">
                    <div className="comment__header">
                        <strong>{activity.initiator.name}</strong>
                        <span>{timeAgo(activity.createdAt)}</span>
                    </div>

                    {editingCommentId === activity._id ? (
                        <>
                            <Editor
                                cardId={cardId}
                                ref={quillRef}
                                value={editingComment}
                                onChange={(value) => setEditingComment(sanitizeHtml(value))}
                            />
                            <div className="editor-action-buttons">
                                <button className="save-editing-content" onClick={handleUpdateComment}>Update</button>
                                <button className="cancel-editing-content" onClick={() => setEditingCommentId(null)}>Discard</button>
                            </div>
                        </>
                    ) : (
                        <div className="card-comment-content" dangerouslySetInnerHTML={{ __html: comment }} />
                    )}
                    {
                        (user.user._id === activity.initiator._id) && (
                            <div className="comment__actions">
                                <a
                                    href="#"
                                    className="comment__action-link"
                                    onClick={() => {
                                        setEditingCommentId(activity._id);
                                        setEditingComment(comment);
                                    }}
                                >
                                    Edit
                                </a>
                                <a href="#" className="comment__action-link" id={`popover-delete-comment-${activity._id}`} onClick={(e) => handleDeleteComment(e, activity._id)}>Delete</a>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    );
};

export default Comment;
