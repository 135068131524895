import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/Slices/modalSlice';
import { selectBoardId, setBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchBoardMembers } from '../../redux/Slices/boardMembersSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import MemberImage from '../Global/MemberImage';
import { find, initialFiltersState } from '../../utils/helpers';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { config } from '../../config';
import useOutsideClick from '../../hooks/useOutsideClick';
import Filter from './Filter';
import { setFilterQuery } from '../../redux/Slices/filterQuerySlice';
import { fetchMembers } from '../../redux/Slices/memberSlice';


const Navbar = ({ board, setBoard }) => {
    const dispatch = useDispatch();
    const filterRef = useRef();

    const boardId = useSelector(selectBoardId);
    const [isShowFilter, setShowFilter] = useState(false);
    const [isLoading, setLoading] = useState(false);

    // const { boardId } = useParams();

    const totalCards = board.actionLists.reduce((count, actionList) => {
        return count + actionList.cards.length;
    }, 0);


    const members = useSelector(state => state.member.boardMembers);
    const boardData = useSelector(state => state.board);

    const query = useSelector((state) => state.filterQuery);



    const handleShareBoardModal = () => {
        dispatch(openModal({ modalType: 'SHARE_BOARD', modalData: {} }));
    };

    useEffect(() => {
        if (boardId) {
            dispatch(fetchMembers({ type: 'board', id: boardId }));
            dispatch(setBoardId(boardId));
        }
    }, [dispatch, boardId, members.length]);

    const handleMemberInfo = (e, contentId) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };
        dispatch(togglePopover({ contentId, position, targetId: target.id }));
    };

    const updateLastViewedBoard = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            await fetch(`${config.API_URI}/api/boards/${boardId}/last-viewed`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };

    useEffect(() => {
        if (boardId) {
            updateLastViewedBoard();
        }
    }, [boardId]);

    useOutsideClick(filterRef, () => setShowFilter(false));

    const updateFilters = async (newFilters) => {
        const accessToken = localStorage.getItem('accessToken');
        setLoading(true);
        try {
            const response = await fetch(`${config.API_URI}/api/users/filters`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ filters: newFilters, boardId })
            });

            if (!response.ok) {
                throw new Error('Failed to update filters');
            }
            const data = await response.json();
            setBoard(data.board || { actionLists: [] });
            setLoading(false);

            console.log('Filters updated successfully:', data);
        } catch (error) {
            console.error('Error updating filters:', error);
        }
    };

    const handleClearFilters = () => {
        updateFilters(initialFiltersState);
        dispatch(setFilterQuery(initialFiltersState));

    }

    return (
        <div className="zoobbe-workspace-navbar" >
            <div className="zoobbe-navbar-left">
                <span className="zoobbe-workspace-name">{board?.title}</span>
                <span className="zoobbe-star">★</span>
                <span className="zoobbe-workspace">Workspace visible</span>
                <button className="zoobbe-board-button">Board</button>
            </div>
            <div className="zoobbe-navbar-right" >
                <div className="filter-content" ref={filterRef} >
                    <button className="zoobbe-filters" onClick={() => setShowFilter(true)}>
                        <span className="material-symbols-outlined">
                            filter_list
                        </span>
                        Filters
                    </button>
                    {
                        query.filteredStatus && (
                            // <button className='cards-count'>
                            //     {isLoading ? <span className="material-symbols-outlined"> motion_photos_on </span> : totalCards}
                            // </button>

                            <button
                                className='cards-count'
                                style={{ paddingRight: isLoading ? '2px' : '4px' }}
                            >

                                {/* <span className={`material-symbols-outlined ${isLoading ? 'spin' : ''}`}>
                                    motion_photos_on
                                </span> */}
                                <span className={`material-symbols-outlined ${isLoading ? 'spin' : ''}`}>
                                    screen_record
                                </span>
                                {
                                    !isLoading && (
                                        <span className='count-number'>
                                            {totalCards}
                                        </span>
                                    )
                                }

                            </button>

                        )
                    }
                    {
                        query.filteredStatus && (
                            <button className="zoobbe-clear" onClick={handleClearFilters}>Clear all</button>
                        )
                    }
                </div>

                <div className="zoobbe-members">
                    <MemberImage members={members} size={30} />

                    {
                        members.length > 3 && (
                            <span className="zoobbe-more-members">+{members.length - 3}</span>
                        )
                    }
                </div>
                <button className="zoobbe-share" onClick={handleShareBoardModal}>Share</button>

                {
                    isShowFilter && (
                        <div ref={filterRef} >
                            <Filter
                                board={board}
                                setBoard={setBoard}
                                isLoading={isLoading}
                                setLoading={setLoading}
                            />
                        </div>
                    )
                }
            </div>

        </div >


    );
};

export default Navbar;
