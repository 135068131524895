import React, { useEffect, useRef, useState, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import MarkdownShortcuts from "quill-markdown-shortcuts";

import EditorToolbar from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./editor.scss";
import "../Workspaces/scss/editor.style.scss";

import {
    mentionedMemberIds,
    resetMentionedIds,
} from "../../redux/Slices/mentionedSlice";
import ImagePlaceholder from "../Global/ImagePlaceholder";
import useOutsideClick from "../../hooks/useOutsideClick";



// Register the MarkdownShortcuts module with Quill
ReactQuill.Quill.register('modules/markdownShortcuts', MarkdownShortcuts);

const Editor = ({ cardId, value, onChange, placeholder = "Write something awesome..." }, ref) => {
    const quillRef = useRef(null);
    const containerRef = useRef(null);
    const [showMention, setShowMention] = useState(false);
    const [mentionPosition, setMentionPosition] = useState({ top: 0, left: 0 });
    const [mentionQuery, setMentionQuery] = useState("");
    const [mentionSuggestions, setMentionSuggestions] = useState([]);
    const [cursorPosition, setCursorPosition] = useState(null);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);

    const {members, boardMembers } = useSelector(state => state.member);

    const [isFocused, setIsFocused] = useState(false);

    const dispatch = useDispatch();


    const modules = {
        toolbar: {
            container: "#toolbar",
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true
        },
        markdownShortcuts: {},
    };

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "align",
        "strike",
        "script",
        "blockquote",
        "background",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "code-block"
    ];

    useEffect(() => {
        if (ref) {
            ref.current = quillRef.current;
        }
        window.quillRef = quillRef.current;

        const handleTextChange = () => {
            if (!(quillRef.current)) {
                return;
            }

            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();
            if (range) {
                setCursorPosition(range);
                const textBeforeCursor = quill.getText(0, range.index);
                const mentionIndex = textBeforeCursor.lastIndexOf('@');

                if (mentionIndex !== -1 && range.index > mentionIndex) {
                    const mentionText = textBeforeCursor.slice(mentionIndex + 1);
                    setMentionQuery(mentionText);
                    if (mentionText.length > -1 && !mentionText.includes(' ')) {
                        setShowMention(true);
                        const bounds = quill.getBounds(mentionIndex);
                        setMentionPosition({
                            top: bounds.top + bounds.height, // Position below the current line
                            left: bounds.left,
                        });
                        // Fetch mention suggestions based on mentionText
                        fetchMentionSuggestions(mentionText);
                    } else {
                        setShowMention(false);
                    }
                } else {
                    setShowMention(false);
                }
            }
        };


        const handleKeyDown = (e) => {
            if (showMention) {
                if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                    e.preventDefault(); // Prevent default arrow key behavior
                    if (e.key === "ArrowDown") {
                        setSelectedSuggestionIndex((prevIndex) =>
                            prevIndex === mentionSuggestions.length - 1 ? 0 : prevIndex + 1
                        );
                    } else if (e.key === "ArrowUp") {
                        setSelectedSuggestionIndex((prevIndex) =>
                            prevIndex === 0 ? mentionSuggestions.length - 1 : prevIndex - 1
                        );
                    }
                } else if (e.key === "Enter" && showMention) {
                    setShowMention(false);
                    handleMentionClick(mentionSuggestions[selectedSuggestionIndex]);
                }

                console.log(selectedSuggestionIndex);
            }
        };

        const quillInstance = quillRef.current ? quillRef.current.getEditor() : null;

        if (!quillInstance) {
            return;
        }

        quillInstance.on('text-change', handleTextChange);
        quillInstance.on('selection-change', handleTextChange);

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            if (quillInstance) {
                quillInstance.off('text-change', handleTextChange);
                quillInstance.off('selection-change', handleTextChange);
            }
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [ref, showMention, mentionSuggestions]);

    const fetchMentionSuggestions = (query) => {
        // Fetch suggestions based on the query
        // This is just a placeholder, replace with actual suggestion fetching logic
        const suggestions = boardMembers?.filter(user => user?.name?.toLowerCase().includes(query.toLowerCase())) || [];

        // Additional suggestions
        const additionalSuggestions = [
            { name: "All members in the card", username: "card", img: "seakashdiu_img_url" }, // Replace with actual image URL
            { name: "All members in the board", username: "board", img: "akash_img_url" }
        ];

        // Combine suggestions and additional suggestions
        const combinedSuggestions = [...suggestions, ...additionalSuggestions];

        // Filter combined suggestions based on the query
        const filteredSuggestions = combinedSuggestions.filter(suggestion =>
            suggestion.name.toLowerCase().includes(query.toLowerCase())
        );

        setMentionSuggestions(filteredSuggestions);
        setSelectedSuggestionIndex(0); // Reset selected index when suggestions are fetched
    };


    const handleMentionClick = (mention) => {
        if (!quillRef.current) {
            return;
        }
        try {
            const quill = quillRef.current.getEditor();
            if (cursorPosition) {
                const textBeforeCursor = quill.getText(0, cursorPosition.index);
                const mentionIndex = textBeforeCursor.lastIndexOf('@');
                quill.deleteText(mentionIndex, cursorPosition.index - mentionIndex);

                const link = `@profile`; // Construct the link based on the mention
                const mentionText = `@${mention.username} `;

                // Insert the mention as a link
                quill.insertEmbed(mentionIndex, 'link', link);
                quill.insertText(mentionIndex, mentionText, 'link', link);

                // Move the cursor to the end of the inserted mention
                quill.setSelection(mentionIndex + mentionText.length);
                setShowMention(false);

                // Update mentioned IDs based on mention type
                if (mention.username === 'card') {
                    boardMembers.map(member => {
                        dispatch(mentionedMemberIds(member._id));
                    })
                } else if (mention.username === 'board') {
                    boardMembers.map(member => {
                        dispatch(mentionedMemberIds(member._id));
                    });
                } else {
                    dispatch(mentionedMemberIds(mention._id));
                }

            }
        } catch (error) {
            console.error("An error occurred while handling the mention click: ", error);
        }
    };


    const handleFocus = () => {
        setIsFocused(true);
    };

    useOutsideClick(containerRef, () => {
        setIsFocused(false);
    });

    return (
        <div
            className={`quill-container${isFocused ? ' editor-focused' : ''}`}
            ref={containerRef}

            onClick={() => {
                if (quillRef.current) {
                    quillRef.current.focus();
                }
            }}
        >
            <EditorToolbar cardId={cardId} />
            <ReactQuill
                ref={quillRef}
                theme="snow"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                modules={modules}
                formats={formats}
                onFocus={handleFocus}
            />

            {showMention && mentionSuggestions.length > 0 && (
                <div
                    className="mention-popover"
                    style={{
                        position: "absolute",
                        top: mentionPosition.top + 50,
                        left: mentionPosition.left,
                        width: '280px'
                    }}
                >
                    {mentionSuggestions.map((suggestion, index) => {

                        return (
                            <div
                                key={index}
                                className={`mention-suggestion ${index === selectedSuggestionIndex ? "selected" : ""}`}
                                onClick={() => handleMentionClick(suggestion)}
                                style={{ display: "flex", alignItems: "center", padding: "5px 10px", cursor: "pointer" }}
                            >
                                {suggestion.profilePicture ? (
                                    <img src={suggestion.profilePicture} alt={suggestion.name} style={{ width: "35px", height: "35px", borderRadius: "50%" }} />
                                ) : (
                                    <ImagePlaceholder key={suggestion._id} size={35} text={suggestion.username} />

                                )}
                                <div>
                                    <div className="suggession-name" style={{ fontWeight: index === selectedSuggestionIndex ? "600" : "600" }}>{suggestion.name}</div>
                                    <div className="suggession-handler" >{`@${suggestion.username}`}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    );
};

export default forwardRef(Editor);
